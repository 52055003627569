<template>
  <div v-cloak>
    <div>
      <div class="wrap">
        <a @click="$router.go(-1)" class="back-link">
          <v-icon class="icon-item icon-chevron-left"></v-icon>
          Назад
        </a>
        <h1>Настройка подсистемы «Индекс клиентоцентричности СЗН»</h1>

        <v-btn class="btn btn-primary" @click="$refs['fileupload'].click()" :disabled="showLoaderDialog">
          Загрузить файл конфигурации
        </v-btn>
        <input type="file"
               accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               ref="fileupload" style="display:none" @change="uploadData($event.target.files[0])">
      </div>
      <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
        Ошибка: {{ errorText }}
      </div>
    </div>
    <LoaderDialog :show="showLoaderDialog" :msg="loaderMsg"></LoaderDialog>
  </div>
</template>

<script>

import LoaderDialog from "@/components/elements/LoaderDialog.vue";
import api from "@/modules/api";

export default {
  name: "ClientCentricityConfig",
  components: {LoaderDialog},
  data() {
    return {
      loaderMsg: 'Загрузка файла',
      showLoaderDialog: false,
      errorText: null,
    };
  },
  methods: {
    async uploadData(file) {
      this.errorText = null;
      if (!file) {
        return;
      }
      this.showLoaderDialog = true;
      const uploadRes = await this.uploadFile(file);
      if (uploadRes) {
        alert('Данные успешно импортированы')
      } else {
        alert('Ошибка при импорте данных')
      }
      this.showLoaderDialog = false;
    },

    async uploadFile(file) {
      if (file.size > 50 * 1048576) {
        alert('Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером');
        return null;
      }
      const formData = new FormData();
      formData.append('file', file);

      const req = await api.postFile("/config-loader/icc/upload_excel", null, formData, 'POST');

      if (req.ok) {
        return req.payload;
      }
      this.errorText = req.errorJson?.detail;
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style scoped lang="scss">

</style>